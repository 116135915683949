<template>
  <b-container>
    <h1>Edit File</h1>
    <b-row v-if="contentReady">
      <b-col lg="4">
        <b-row>
          <b-col>
            <b-card>
              <h2
                class="mb-3"
                style="font-size: 1.5rem;"
              >
                File Info
              </h2>
              <file-info :file="file" />
            </b-card>
          </b-col>
          <b-col class="mt-4">
            <b-card>
              <h2
                class="mb-3"
                style="font-size: 1.5rem;"
              >
                Publishing
              </h2>
              <b-form-group>
                <b-form-checkbox v-model="accessibilityAck">
                  I acknowledge that I have provided an accessible PDF according to the
                  <a
                    target="_blank"
                    href="https://www.uc.edu/about/accessibility-network/documents.html"
                  >UC Accessibility Office Standards</a>.
                </b-form-checkbox>
                <small>
                  If a reviewer finds that the PDF is not accessible, your flipbook will be unpublished.
                </small>
              </b-form-group>
              <b-btn
                v-if="!file.published"
                block
                variant="success"
                :disabled="update_in_prog"
                @click="update"
              >
                Save Draft
              </b-btn>
              <b-btn
                :disabled="!allowSave || update_in_prog"
                block
                variant="success"
                @click="publish"
              >
                {{ file.published ? `Update` : `Publish` }}
              </b-btn>
              <b-btn
                v-if="file.published"
                block
                variant="warning"
                :disabled="update_in_prog"
                @click="unpublish"
              >
                Unpublish
              </b-btn>
              <b-btn
                v-if="!file.published"
                block
                variant="danger"
                :disabled="update_in_prog"
                @click="deleteFile"
              >
                Delete
              </b-btn>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
      <b-col lg="8">
        <file-edit
          :file.sync="file"
        />
        <b-card class="mt-4">
          <h2
            class="mb-3"
            style="font-size: 1.5rem;"
          >
            <span class="pr-3">File Double Pages</span>
            <b-icon
              id="doublePage"
              icon="question-circle-fill"
              variant="primary"
              scale="0.75"
            />
            <b-tooltip
              target="doublePage"
              variant="primary"
              placement="bottom"
              triggers="hover"
            >
              <p>Some PDFs are exported in booklet form (landscape).</p>
              <p>If this page in the PDF you uploaded is really 2 pages in print, check this box</p>
            </b-tooltip>
          </h2>
          <b-form-group
            v-for="page in orderBy(file.pages, `page_number`)"
            :key="page.id"
          >
            <b-form-checkbox
              v-model="page.is_double"
              inline
            >
              Page {{ page.page_number }} is a double page
            </b-form-checkbox>
          </b-form-group>
        </b-card>
        <b-card class="mt-4">
          <h2
            class="mb-3"
            style="font-size: 1.5rem;"
          >
            Replace File
          </h2>
          <replace-file
            :original-file="file"
            @uploaded="onFileReplaced"
          />
        </b-card>
      </b-col>
    </b-row>
    <loader v-else />
  </b-container>
</template>

<script>
import { orderBy } from 'lodash';
import { FileService } from '@/services';
import FileInfo from '@/components/FileInfo';
import FileEdit from '@/components/FileEdit';
import ReplaceFile from '@/components/ReplaceFile';
import toastr from '@/plugins/notifications';

export default {
  name: `FileEditPage`,
  components: {
    FileInfo,
    FileEdit,
    ReplaceFile,
  },
  data() {
    return {
      file: null,
      accessibilityAck: false,
      contentReady: false,
      update_in_prog: false,
    };
  },
  computed: {
    allowSave() {
      return this.accessibilityAck;
    },
  },
  async created() {
    this.file = await FileService.getById({ id: this.$route.params.file_id, include_pages: false });
    this.contentReady = true;
  },
  methods: {
    async deleteFile() {
      if (await this.confirmDelete()) {
        this.update_in_prog = true;
        await FileService.delete(this.file.id);
        toastr.success(`Successfully Deleted Flipbook`);
        this.$router.push(`/admin`);
      }
    },
    async update() {
      this.update_in_prog = true;
      this.file = await FileService.update(this.file.id, this.file);
      this.accessibilityAck = false;
      toastr.success(`Successfully Updated Flipbook`);
      this.update_in_prog = false;
    },
    async publish() {
      this.update_in_prog = true;
      this.file = await FileService.publish(this.file.id, this.file);
      this.accessibilityAck = false;
      toastr.success(`Successfully Published Flipbook`);
      this.update_in_prog = false;
    },
    async unpublish() {
      this.update_in_prog = true;
      this.file = await FileService.unpublish(this.file.id);
      this.accessibilityAck = false;
      toastr.success(`Successfully Unpublished Flipbook`);
      this.update_in_prog = false;
    },
    orderBy,
    async confirmDelete() {
      return await this.$bvModal.msgBoxConfirm(
        this.$createElement(`div`, [
          this.$createElement(`p`, `You are about to delete this file.`),
          this.$createElement(`p`, `This cannot be undone.`),
          this.$createElement(`p`, `Would you like to continue?`),
        ]),
        {
          title: `Warning!`,
          size: `md`,
          buttonSize: `sm`,
          okVariant: `danger`,
          okTitle: `YES`,
          cancelTitle: `NO`,
          footerClass: `p-2`,
          centered: true,
        },
      );
    },
    onFileReplaced(file) {
      this.$router.push(`/admin/file/${file.id}`);
    },
  },
};
</script>
