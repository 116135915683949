<template>
  <div>
    <b-form-group id="file-group">
      <b-form-file
        ref="file"
        v-model="file"
        accept=".pdf"
        required
        placeholder="Choose a .pdf file"
        :disabled="originalFile.published"
      />
    </b-form-group>
    <b-tooltip
      v-if="originalFile.published"
      target="file-group"
      variant="primary"
      placement="bottom"
      triggers="hover"
    >
      You must unpublish this flipbook before replacing the source file
    </b-tooltip>
    <b-form-group>
      <b-button
        :disabled="!allowSubmit"
        class="mr-2"
        @click="uploadFile"
      >
        Submit
      </b-button>
    </b-form-group>
  </div>
</template>

<script>
import { FileService } from "@/services";
import toastr from "@/plugins/notifications";

export default {
  name: `ReplaceFile`,
  props: {
    originalFile: { required: true, type: Object },
  },
  data() {
    return {
      file: null,
      disableUpload: false,
    };
  },
  computed: {
    allowSubmit() {
      return !this.disableUpload && this.file && !this.originalFile.published;
    },
  },
  methods: {
    async uploadFile() {
      this.disableUpload = true;
      const formData = new FormData();
      formData.append(`file`, this.file);
      toastr.info(
        `File upload in progress... You will be redirected when it is completed`,
      );
      const file = await FileService.replace(this.originalFile.id, formData);
      this.$emit(`uploaded`, file);
      this.file = null;
      this.disableUpload = false;
    },
  },
};
</script>
