<!-- eslint-disable vue/no-mutating-props -->
<template>
  <b-card>
    <h2
      class="mb-3"
      style="font-size: 1.5rem;"
    >
      Edit File Details
    </h2>
    <b-form-group label="File Name">
      <b-form-input
        v-model="file.name"
        placeholder="This name is used to identify the file and is shown on the browser tab."
      />
    </b-form-group>
    <b-form-group>
      <template #label>
        <span class="pr-3">Scale Ratio</span>
        <b-icon
          id="scaleRatioHelp"
          icon="question-circle-fill"
          variant="primary"
        />
        <b-tooltip
          target="scaleRatioHelp"
          variant="primary"
          placement="bottom"
          triggers="hover"
        >
          <p>If the PDF you uploaded is portrait 8.5in x 11in, leave this value at 1.5.</p>
          <p>If the PDF you uploaded is landscape 8.5in x 11in, change this value to 1.28.</p>
          <p>Otherwise, you will need to adjust this value until your flipbook shows correctly on the preview page.</p>
        </b-tooltip>
      </template>
      <b-form-input
        v-model="file.size_ratio"
        placeholder="This is used to properly display the flipbook. The default is 1.5 for an 8.5 x 11 flipbook."
      />
    </b-form-group>
  </b-card>
</template>

<script>
export default {
  name: `FileEdit`,
  props: {
    file: { type: Object, required: true },
  },
};
</script>
